<template>
  <section>
    <v-row>
      <p class="text-h6 text--secondary mb-0">
        {{ correlativoSolicitud }}
      </p>
    </v-row>
    <titulo />
    <proveedores :prevRoute="prevRoute" :headers="encabezadosGanadores" />
    <ChatComponent :idSolicitud="handleIDSolicitud" modelo="SolicitudCompra" />
  </section>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import titulo from "./components/TituloVistaComponent.vue";
import proveedores from "../../components/ProveedorComponent.vue";
import ChatComponent from "@/components/ChatComponent";

export default {
  name: "listaGanadoresView",
  components: {
    titulo,
    proveedores,
    ChatComponent,
  },
  props: {
    idSolicitud: Number,
  },
  data: () => ({
    prevRoute: null,
    encabezadosGanadores: [
      {
        text: "Correlativo",
        align: "center",
        value: "correlativo",
        sortable: false,
      },
      {
        text: "Proveedor",
        value: "ganador_obs",
      },
      {
        text: "Estado",
        value: "estado_ganador",
        width: 180,
      },
      {
        text: "Acciones",
        value: "accionesGanadores",
        sortable: false,
        align: "center",
      },
    ],
  }),
  computed: {
    ...mapState("agregarInsumo", ["id_solicitud", "proveedoresList"]),
    ...mapState("comprasModule", [
      "pasoSolicitud",
      "correlativoSolicitud",
      "pasosSolicitudes",
    ]),
    /**
     * Manejador para el id solicitud, cuando venga de vuex o por route props
     */
    handleIDSolicitud() {
      if (this.idSolicitud) {
        return this.idSolicitud;
      } else return this.id_solicitud;
    },
  },
  methods: {
    ...mapMutations("agregarInsumo", ["setProveedoresList"]),
    async getProvidersGanadores() {
      const { status, data } =
        await this.services.SolicitudCompra.getSolicitudCompraGanadores(
          this.handleIDSolicitud
        );

      if (status == 200) {
        this.setProveedoresList(data);
      }
    },
  },
  created() {
    this.handleIDSolicitud
      ? this.getProvidersGanadores()
      : this.$router.replace({ name: "list-solicitudes-compra" });
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.name;
    });
  },
};
</script>
<style>
.theme--light.v-application .text--secondary {
  color: #404a7a !important;
}
</style>
