import store from "../store";
import SocketIO from "socket.io-client";
import AuthServices from "@/services/Auth.services";

export default SocketIO(`${process.env.VUE_APP_SOCKET_URL}`, {
  auth: {
    token: store.state.token || localStorage.getItem("token"),
  },
  transports: ["polling", "websocket"],
  reconnection: {
    attempts: 20,
    delay: 1000,
    onReconnectError: async (error) => {
      // const { status, mensaje } = await AuthServices.almacenarErrorConexion({
      //   error: error.message,
      // });
      console.log("error", error);
    },
  },
});
