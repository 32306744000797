<template>
  <div>
    <v-btn
      @click="openChat()"
      :class="`btnStyle ${!socketConnected ? 'secondary--text' : 'white'}`"
      :rounded="!socketConnected"
      :fab="socketConnected"
      dark
      :color="!socketConnected ? 'transparent' : 'primary'"
    >
      <v-icon dark> mdi-message-text </v-icon> <span v-if="!socketConnected">Conectando...</span>
    </v-btn>
    <v-dialog 
      v-model="dialogMessage"
      :hide-overlay="!$vuetify.breakpoint.smAndDown"
      :persistent="$vuetify.breakpoint.smAndDown"
      max-width="400"
      transition="dialog-bottom-transition"
      scrollable
      @click:outside="closeChatModal()"
    >
      <v-card height="650" class="card-container">
        <v-btn @click="dialogMessage = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-tabs v-model="tabsChat" class="d-flex justify-center mt-3" center-active flat>
          <v-tab><v-icon>mdi-forum</v-icon></v-tab>
          <v-tab><v-icon>mdi-account</v-icon></v-tab>
          <v-tabs-slider color="secondary" />
        </v-tabs>

        <v-card-text class="mt-5" style="overflow: hidden">
          <v-tabs-items v-model="tabsChat">
            <v-tab-item>
              <div class="py-4 px-4">
                <div style="min-height: 200px; max-height: 350px; overflow-y: auto;">
                  <div
                    class="my-1"
                    v-for="(mensaje, index) in mensajes" :key="index"
                    :ref="index == mensajes.length - 1 ? 'lastItem' : ''"
                  >
                    <div v-if="loadingMore" class="d-flex justify-center">
                      <v-progress-circular indeterminate color="secondary"></v-progress-circular>
                    </div>

                    <v-card v-if="mensaje.id_usuario !== userInfo.user.id" color="bgMinsal" elevation="5"
                      class="rounded-lg message-recived" max-width="200">
                      <v-card-title class="text-subtitle-2">
                        {{ mensaje.nombre || "Usuario" }}
                      </v-card-title>
                      <v-card-text>{{ mensaje.mensaje }}</v-card-text>
                      <v-spacer />
                      <v-row class="justify-end pr-2">
                        <v-card-subtitle class="grayMinsal--text">{{
                          moment(mensaje.created_at).format("DD/MM/YYYY h:mm a")
                        }}</v-card-subtitle>
                      </v-row>
                    </v-card>

                    <v-card v-else color="#C1ECD9" elevation="5" class="rounded-lg message-response" max-width="200">
                      <v-card-title class="text-subtitle-2">
                        {{ mensaje.nombre }}
                      </v-card-title>
                      <v-card-text>{{ mensaje.mensaje }}</v-card-text>
                      <v-spacer />
                      <v-row class="justify-end pr-2">
                        <v-card-subtitle class="grayMinsal--text">{{
                          moment(mensaje.created_at).format("DD/MM/YYYY h:mm a")
                        }}</v-card-subtitle>
                      </v-row>
                    </v-card>
                  </div>
                </div>

                <v-progress-linear value="100" class="mt-5" />
                <v-textarea v-model="mensajeTo" append-outer-icon="mdi-send" outlined rows="2"
                  class="mt-5 scroll-textarea" hide-details no-resize @click:append-outer="sendMessage()"
                  @keydown.enter.prevent="sendMessage()" />
              </div>
            </v-tab-item>

            <v-tab-item>
              <p class="text-h6 secondary--text pt-4 px-4 mb-0">Usuarios conectados</p>

              <div class="online-chat py-4 px-4">
                <v-card v-for="usuario in usuarios" :key="usuario.id" color="white" flat>
                  <v-card-title class="text-subtitle-2">
                    {{ usuario.nombre }} ({{ usuario.email }})
                  </v-card-title>
                  <v-divider />
                </v-card>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import socketConfig from "../plugins/ws";
import { mapState, mapMutations } from "vuex";
import moment from "moment";
export default {
  name: "ChatComponent",
  props: {
    idSolicitud: {
      type: Number | String,
    },
    modelo: {
      type: String,
    },
    callback: {
      type: Function,
    },
  },
  data: () => ({
    dialogMessage: false,
    tabsChat: null,
    result: 7,
    mensajeTo: "",
    mensajes: [],
    notificaciones: [],

    usuarios: [],
    currentPage: 1,
    loadingMore: false,
    socketConnected: false,
  }),
  computed: {
    ...mapState(["userInfo", "sockets", "token"]),
  },
  methods: {
    ...mapMutations(["setSocket"]),
    scrollConfig() {
      setTimeout(() => {
        if (this.$refs.lastItem) {
          const [el] = this.$refs.lastItem;
          if (el) {
            el.scrollIntoView({ behavior: "smooth" });
          }
        }
      }, 100);
    },
    openChat() {
      this.dialogMessage = !this.dialogMessage && this.socketConnected;
      this.scrollConfig();
    },
    sendMessage() {
      if (this.mensajeTo == "") return;

      const payload = {
        referencia: this.idSolicitud,
        modelo: this.modelo,
        mensaje: this.mensajeTo,
        id_usuario: this.userInfo.user.id,
      };

      this.sockets.chats.emit("message", payload);

      this.mensajes.push({
        id: (this.result += 1),
        created_at: moment(),
        nombre: `${this.userInfo.user.primer_nombre || "Usuario"} ${this.userInfo.user.segundo_nombre || ""
          } ${this.userInfo.user.primer_apellido || ""} ${this.userInfo.user.segundo_apellido || ""
          }`,
        // cargo: "Jefe unidad",
        mensaje: this.mensajeTo,
        titulo: "Mensaje",
        id_usuario: this.userInfo.user.id,
      });

      (this.mensajeTo = ""), this.scrollConfig();

      if (this.callback != null) (this.callback)(payload);
    },
    async getMessagesList() {
      const response = await this.services.Grupos.getMensajes(this.idSolicitud, {
        modelo: this.modelo,
      });

      if (response?.status == 200) {
        this.mensajes = response?.data
          .map((col) => ({
            ...col,
            nombre: `${col.primer_nombre || "Usuario"} ${col.segundo_nombre || ""
              } ${col.primer_apellido || ""} ${col.segundo_apellido || ""}`,
          }))
          .reverse();
      }
    },
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      const element = document.getElementById("lastItem");
      if (scrollTop == 0) {
        this.currentPage += 1;
        this.loadingMore = true;
        const response = await this.services.Grupos.getMensajes(this.idSolicitud, {
          modelo: this.modelo,
          page: this.currentPage,
        }).catch(() => {
          this.loadingMore = false;
        });

        if (response.data.length == 0) {
          this.temporalAlert({
            show: true,
            message: "No se han encontrado mensajes nuevos",
            type: "info",
          });
        }

        let mappedResponse = response?.data
          .map((col) => ({
            ...col,
            nombre: `${col.primer_nombre || "Usuario"} ${col.segundo_nombre || ""
              } ${col.primer_apellido || ""} ${col.segundo_apellido || ""}`,
          }))
          .reverse();

        this.loadingMore = false;
        this.mensajes.unshift(...mappedResponse);
        element.scrollTop = 100;
      }
    },
    closeChatModal() {
      if (!this.$vuetify.breakpoint.smAndDown) this.dialogMessage = false;
    },
  },
  created() {
    if (this.idSolicitud) this.getMessagesList();
  },
  mounted() {
    if (!this.sockets.chats) {
      this.setSocket({ socket: 'chats', connection: socketConfig });
      this.sockets.chats.on('connect', () => {
        console.log('Connected (2)');
        this.socketConnected = true;

        this.sockets.chats.on('solicitudes', (data) => {
        this.mensajes.push({
            ...data,
            nombre: `${data.primer_nombre || "Usuario"} ${data.segundo_nombre || ""
              } ${data.primer_apellido || ""} ${data.segundo_apellido || ""}`,
          });
          this.scrollConfig();
        });

        this.sockets.chats.on('usuarios', (data) => {
          this.usuarios = data.map((col) => ({
            nombre: `${col.primer_nombre || "Usuario"} ${col.segundo_nombre || ""
              } ${col.primer_apellido || ""} ${col.segundo_apellido || ""}`,
              email: col.email,
          }));
        });

        this.sockets.chats.on('disconnect', () => console.log('Disconnected (2)'));

        if (this.idSolicitud) {
          this.sockets.chats.emit("join-chat", {
            id_usuario: this.userInfo.user.id,
            id_solicitud: this.idSolicitud,
            modelo: this.modelo,
          });
        }
      });

      this.sockets.chats.nsp = '/chats';
      this.sockets.chats.connect();
    }
  },
  beforeDestroy() {
    this.sockets.chats.emit("leave-chat", {
      id_usuario: this.userInfo.user.id,
      id_solicitud: this.idSolicitud,
      modelo: this.modelo,
    });
    this.sockets.chats.disconnect();
    this.setSocket({ socket: 'chats', connection: null });
  },
};
</script>
<style lang="scss" scoped>
.card-container {
  max-width: 400px;
  position: absolute !important;
  bottom: 100px !important;
  right: 20px !important;
}

@media (max-width: 600px) {
  .card-container {
    max-width: 94% !important;
    bottom: 100px !important;
    right: 3% !important;
    left: 3% !important;
  }
}

.btnStyle {
  position: fixed;
  bottom: 3vh;
  right: 5vh;
  z-index: 1;
}

.input-chat {
  padding-top: 200px;
  background-color: rgb(247, 244, 244);
  overflow-y: scroll;
  max-height: 410px;
  margin-left: 0px;
  margin-right: 0px;
}

.online-chat {
  overflow-y: scroll;
  max-height: 450px;
}

.notifications-chat {
  overflow-y: scroll;
  max-height: 450px;
}

.v-slide-group__prev {
  display: none !important;
}

.message-response {
  margin-left: 7vw;
  margin-bottom: 3vw;
}

.message-recived {
  margin-left: 2vw;
  margin-right: 3vw;
  margin-bottom: 3vw;
}

.dialog-chat::-webkit-scrollbar {
  width: 7px !important;
  background-color: #ffffff;
}

.dialog-chat::-webkit-scrollbar-thumb {
  background: #111c4e;
  border-radius: 20px;
}

.v-tabs {
  flex: initial !important;
}
</style>
