<template>
  <section>
    <p class="mb-5">Listado de proveedores</p>
    <v-data-table
      :headers="headers"
      :items="proveedoresList"
      item-value="correlativo"
      hide-default-footer
      no-data-text="No hay datos para mostrar"
      :items-per-page="-1"
    >
      <template v-slot:[`item.correlativo`]="{ item }">
        {{ proveedoresList.map((col) => col.id).indexOf(item.id) + 1 }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        <v-chip
          label
          :dark="item.estado_proveedor_web?.nombre === 'Descartado'"
          :color="item.estado_proveedor_web?.color"
        >
          {{ item.estado_proveedor_web?.nombre || "" }}
        </v-chip>
      </template>
      <template v-slot:[`item.estado_ganador`]="{ item }">
        <v-chip
          :dark="item.estado_ganador === 'Contratado'"
          label
          :color="item.estado_ganador_color"
        >
          {{ item.estado_ganador || "" }}
        </v-chip>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="verificarProveedor(item)"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon>{{
                pasoSolicitud?.id === 4
                  ? "mdi-eye"
                  : "mdi-clipboard-check-outline"
              }}</v-icon>
            </v-btn>
          </template>
          <span>
            {{
              pasoSolicitud?.id === 4 ? "Ver detalle" : "Verificar proveedor"
            }}
          </span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.accionesGanadores`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="goToInsumoDetalle(item)"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span> Ver detalle </span>
        </v-tooltip>
        <v-tooltip right v-if="[1, 2].includes(item.id_estado_ganador)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="crearContrato(item)"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon>mdi-clipboard-check-outline</v-icon>
            </v-btn>
          </template>
          <span v-text="returnTextTooltip(Number(item.id_estado_ganador))" />
        </v-tooltip>
        <v-tooltip right v-if="item.id_estado_ganador === 3">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="verLiquidacion(item)"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon>mdi-clipboard-check-outline</v-icon>
            </v-btn>
          </template>
          <span> Ver liquidación</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.accionesGanadoresPAAC`]="{ item }">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="seguimientoContratoPAAC(item)"
              v-bind="attrs"
              v-on="on"
              icon
              small
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span> Seguimiento de contrato </span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-btn class="mt-10 mx-4 text-capitalize button-extra" outlined @click="goBack()"> Volver </v-btn>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "ProveedorComponent",
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    prevRoute: {
      type: String,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("agregarInsumo", ["id_solicitud", "proveedoresList"]),
    ...mapState("comprasModule", ["pasoSolicitud"]),
  },
  methods: {
    ...mapMutations("agregarInsumo", [
      "setProveedoresList",
      "setCurrentProvider",
      "setProviderInfo",
    ]),
    ...mapMutations("procesoCompra", [
      "setIdOrdenCompra",
      "setContratoExistente",
    ]),
    returnTextTooltip(id_estado){
      switch (id_estado) {
        case 1:
          return "Crear contrato"
        case 2:
          return "Seguimiento de contrato"
        default:
          return ""
      }
    },
    verificarProveedor(item) {
      this.setCurrentProvider(item.id);
      this.setProviderInfo(item);
      this.$router.push({
        name: "verificar-proveedor",
        params: { tipo: Number(this.$route.params.tipo) },
        query: { proveedor: item.id },
      });
    },
    goToInsumoDetalle(item) {
      this.setCurrentProvider(item.id_referencia);
      this.setProviderInfo(item);
      this.$router.push({
        name: "proveedor-detalle",
        params: { tipo: Number(this.$route.params.tipo) },
        query: { proveedor: item.id_referencia},
      });
    },
    async crearContrato(item) {
      this.setCurrentProvider(item.id_referencia);

      await this.fetchProviderWeb(item.id_ganador_obs);
      const { status, data } =
        await this.services.ContratoService.getContratoGanador(
          item.id_ganador_obs
        );

      if (status === 200 && data) {
        if (item.id_estado_ganador === 1) {
          this.setContratoExistente(data);
          this.$router.push({
            name: "crear-contrato-compra",
            params: {
              tipo: Number(this.$route.params.tipo),
            },
          });
        } else {
          this.$router.push({
            name: "seguimiento-orden-compra",
            params: { idContrato: Number(data.id) },
          });
        }
      } else {
        this.$router.push({
          name: "crear-contrato-compra",
          params: { tipo: Number(this.$route.params.tipo) },
        });
      }
    },
    async seguimientoContratoPAAC(item) {
      this.setCurrentProvider(item.id_referencia);

      this.fetchProviderWeb(item.id);
      const { status, data } =
        await this.services.ContratoService.getContratoGanador(item.id);
      if (status === 200 && data) {
        this.$router.push({
          name: "seguimiento-orden-compra",
          params: { idContrato: Number(data.id) },
        });
      }
    },
    async fetchProviderWeb(ganador) {
      let response =
        await this.services.ContratoService.getGanadorProveedorInfo(ganador);
      if (response?.status == 200) {
        response.data.id_ganador = ganador;
        this.setProviderInfo(response?.data);
      }
    },
    async verLiquidacion(item) {
      const { status, data } =
        await this.services.ContratoService.getContratoGanador(
          item.id_ganador_obs
        );
      if (status === 200) {
        this.$router.push({
          name: "liquidacion-orden-compra",
          params: { idContrato: Number(data.id) },
        });
      }
    },
    goBack() {
      if (
        this.prevRoute === "verificar-proveedor" ||
        this.prevRoute === "proveedor-detalle"
      )
        this.$router.push("/proceso-solicitud-compra/list-solicitudes");
      else this.$router.back();
    },
  },
  beforeDestroy() {
    this.setProveedoresList([]);
  },
};
</script>
<style lang="scss" scoped>
:deep(.v-chip) {
  width: -webkit-fill-available !important;
  display: flex !important;
  justify-content: center !important;
}
</style>
