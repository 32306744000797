<template>
  <v-row class="flex-column">
    <p class="text-h6 text--secondary mb-0">
      {{ getSolicitudMensaje }}
    </p>
    <p class="text-subtitle-1">{{selectedUnidad.nombre || ""}}</p>

    <p class="text-subtitle-1">Ejercicio {{ anioFiscal }}</p>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "TituloVistaComponent",
  computed: {
    ...mapState(["selectedUnidad", "anioFiscal"]),
    getSolicitudMensaje() {
      switch (this.$route.params.tipo) {
        case 1:
          return "Solicitud de obras, bienes o servicios";
        case 2:
          return "Solicitud de compra por convenio Marco";
        case 3:
          return "Solicitud de compra en línea de bienes o servicios";
      }
    },
  },

};
</script>
